<template>
  <div class="integralHtml">
    <div>
      <div style="position: sticky;top: 0;z-index: 999;">
        <van-tabs v-model="active"
          color="#ffc002"
          @click="onClick"
          :sticky="true">
          <!-- <van-tab title="全部" name="0"></van-tab> -->
          <van-tab title="已报名"
            name="1"></van-tab>
          <van-tab title="已完成"
            name="2"></van-tab>
          <van-tab title="已拒绝"
            name="3"></van-tab>
        </van-tabs>
      </div>
      <div class="integral"
        v-for="item in list"
        :key="item">
        <div class="integralName">
          <span>姓名: {{item.Name}}</span>
          <span>电话: {{item.Mobile}}</span>
        </div>
        <div class="integralParty">报名时间: {{item.EnrolTime}}</div>
        <div v-if="item.Status == 3 && item.Refreason != null"
          class="integralParty">未通过原因: {{item.Refreason}}</div>
      </div>
      <van-empty v-if="zanwuShow"
        style="background: #ffff;"
        description="暂无报名信息" />
    </div>
  </div>
</template>

<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WxGetMyActEnrolPage } from "@/api/RealInfo";
export default {
  data () {
    return {
      active: 0,
      list: [],
      listfrom: {
        page: 1,
        limit: 99,
        name: '',
        mobile: '',
        status: 1,
      },
      zanwuShow: false
    }
  },
  methods: {
    onClick: function (name, title) {
      console.log(name);
      this.listfrom.status = name
      this.listfrom.page = 1;
      this.list = []
      this.getList()

    },
    // 获取分页列表
    getList () {
      this.listfrom.openID = getOpenId()
      this.listfrom.promotionId = this.$route.params.id
      WxGetMyActEnrolPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = [];
              this.zanwuShow = true
            } else {
              this.list = res.data.data;
              this.zanwuShow = false
            }
          }
        })
        .catch(() => { });
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getList();
  },
}
</script>

<style>
.integralHtml {
  /* background-color: rgb(246, 242, 242); */
}
.integral {
  width: 90%;
  margin: 10px 5%;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.integralName {
  padding: 15px 0px 12px 15px;
  font-size: 15px;
  overflow: hidden;
}
.integralName span:nth-child(1) {
  float: left;
}
.integralName span:nth-child(2) {
  float: right;
  margin-right: 15px;
}
.integralParty {
  padding-left: 15px;
  padding-bottom: 15px;
  font-size: 14px;
  color: rgb(172, 167, 167);
}
</style>